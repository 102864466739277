import DashboardToolbarContainer from "components/dashboard/DashboardToolbar/DashboardToolbarContainer";
import PaletteContainer from "containers/dashboard/PaletteContainer";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Scrollbars} from 'react-custom-scrollbars';
import withStyles from "@material-ui/core/styles/withStyles";
import RGL, {WidthProvider} from "react-grid-layout";
import * as _ from 'lodash';
import DashletContainer from "components/dashboard/dashlets/DashletContainer";
import * as uuid from "uuid";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CircularProgress, Divider, Typography } from "@material-ui/core";
import NewDashlet from "components/dashboard/dashlets/NotSupportedDashlet/NewDashlet";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Tooltip } from "@material-ui/core";
import { toastr } from 'react-redux-toastr';
import { ANOMALY_DASHBOARD, DEFAULT_ACCORDIANS_LOAD_COUNT, DEFAULT_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { LOAD_ANOMALY_DASHBOARD_DATA_SUBSCRIPTION } from "dashlets/HLCChartDashlet/webSocketClient";
import { Add20Filled, ChevronDown12Regular, ChevronRight12Regular, ChevronUp12Regular, Delete20Filled } from "@fluentui/react-icons";
import { showErrorNotification } from "components/toastr/notificationActions";
import PEIChartBagWebSocketSubscription from "dashlets/PEIChartBagDashlet/components/PEIChartBagWebSocketSubscription";

const ReactGridLayout = WidthProvider(RGL);

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    padding: theme.spacing(0),
  },
  content: {
    position: 'absolute',
    top: '30%',
    right: '35%',
    width: '30%',
    height: 'auto',
    minHeight: '100px',
    margin: 'auto',
    padding: '30px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#333',
    backgroundColor: '#e7f3fe',
    border: '2px solid #a3c9e8',
    borderRadius: '12px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  rootGrid: {
    paddingRight: theme.spacing(1),
  },
  grid: {
    height: 'calc(100% - 120px)',
  },
  scrollbarWrapper: {
    height: 'calc(100% - 168px)' //120px (palette) + 48px (toolbar)
  },
  dashboardRow: {
    display: 'flex',
    alignItems: 'center',
    height: '35px',
    width: 'calc(100% - 24px)',
    border: '1px solid #e1e1e1',
    borderBottom: 'none',
  },
  menuItem: {
    paddingTop: '5px',
    width: '100% !important',
    borderRadius: '5px 5px 0 0',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Segoe UI'
  },
  addIcon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    fontSize: '2.5rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  newCard: {
    position: 'fixed',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    width: '300px',
    background: 'none',
  },
  smallAddIcon: {
    position: 'absolute',
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    right: '66px',
    // top: '50%', 
    transform: 'translateY(-50%)',
    // top: '20px', 
  },
 smallDeleteIcon: {
  position: 'absolute',
  fontSize: '1.3rem',
  color: theme.palette.primary.main,
  cursor: 'pointer',
  right: '38px', 
  // top: '50%', 
  transform: 'translateY(-50%)',
  // top: '20px', 
 },
  editbarWrapper: {
    font: 'inherit',
    color: '#242424',
    border: '0',
    borderBottom: '2px solid transparent',
    margin: '0',
    display: 'block',
    padding: '3px 0px 3px 7px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#f2f3f3',
    borderBottom: '2px solid red',
    width: '100%',
    maxWidth: "40%",
    '&:focus': {
      outline: 'none', 
      borderBottom: '2px solid #0F6CBD;', 
      // borderBottom: '2px solid #5797c7;', 
    },
  },
  arrowIcon: {
    marginRight: "2px",
    cursor: "pointer"
  },
});

class DashboardWorkspace extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isResizing: false,
      tabData: {},
      searchName: "",
      databaseName: "",
      allTabData: {},
      startIndex: 0,
      endIndex: DEFAULT_ACCORDIANS_LOAD_COUNT,
      isLoading: false,
    };
    this.loadMoreData = this.loadMoreData.bind(this);
  }

  componentDidMount() {
    const { activeDashboard, activeDashlets } = this.props;
    if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
      this.setState({ startIndex: 0, endIndex: DEFAULT_ACCORDIANS_LOAD_COUNT, isLoading: true })
    }
    this.setTabData();
        // if (selectedTimeUnitNumber && selectedTimeUnitNumber != "Off") {
        //   clearInterval(this.intervalId);
        //   const mills = this.getMilliseconds(selectedTimeUnitNumber);
        //   this.startLogging(mills)
        // }
        // if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
        //   if (activeDashboard.dashlets && activeDashboard.dashlets.length > 0) {
        //     const dashlet = activeDashlets[activeDashboard.dashlets[0]];
        //     this.props.createAnomalyDashboard(dashlet.config.backendKey, activeDashboard.id)
        //   }
        // }
    // }
    // const { activeDashboard, activeDashlets } = this.props;
    // if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
    //   if (activeDashboard.dashlets && activeDashboard.dashlets.length > 0) {
    //     const dashlet = activeDashlets[activeDashboard.dashlets[0]];
    //     this.props.createAnomalyDashboard(dashlet.config.backendKey, activeDashboard.id)
    //   }
    // }
    // if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
    //   let selectedTimeUnitNumber = activeDashboard.timeSettings.refreshTime;
    //   //called when dashboard change
    //   // this.props.createAnomalyDashboard(activeDashboard);

    //   //schedule the anomaly dashboard
    //   if (selectedTimeUnitNumber && selectedTimeUnitNumber != "Off") {
    //     clearInterval(this.intervalId);
    //     const mills = this.getMilliseconds(selectedTimeUnitNumber);
    //     console.log("handleLaodAomalyDashboardData", selectedTimeUnitNumber)
    //     this.startLogging(mills)
    //   } else {
    //     clearInterval(this.intervalId);
    //   }
    // }
  }

  componentDidUpdate(prevProps) {
    const { activeDashboard, activeDashlets, refreshDashboardInit } = this.props;
    const { setRefreshDashboardInit, refreshDashboard } = this.props;
    // let selectedTimeUnitNumber = activeDashboard.timeSettings.refreshTime
    if (activeDashboard && refreshDashboardInit[activeDashboard.id] && refreshDashboardInit[activeDashboard.id].init) {
        setTimeout(() => {
            if (refreshDashboard) {
              refreshDashboard(activeDashboard.id);
            }
            if (setRefreshDashboardInit) {
              setRefreshDashboardInit({dashboardId: activeDashboard.id, init: false});
            }
        }, 25000)
    }

    if (prevProps.activeDashboard !== this.props.activeDashboard) {
      if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
        this.setState({ startIndex: 0, endIndex: DEFAULT_ACCORDIANS_LOAD_COUNT, isLoading: true })
      }
      this.setTabData();

      if (prevProps.activeDashboard?.id !== this.props.activeDashboard?.id) {
        if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
          let selectedTimeUnitNumber = activeDashboard.timeSettings.refreshTime
          //called when dashboard change
          this.props.createAnomalyDashboard(activeDashboard)

          //schedule the anomaly dashboard
          if (selectedTimeUnitNumber && selectedTimeUnitNumber != "Off") {
            clearInterval(this.intervalId);
            const mills = this.getMilliseconds(selectedTimeUnitNumber);
            this.startLogging(mills);
          } else {
            clearInterval(this.intervalId);
          }
        }
      } else if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {          
        if (prevProps.activeDashboard.timeSettings != this.props.activeDashboard.timeSettings 
          || prevProps.activeDashboard.timeSettings.refreshTime !== this.props.activeDashboard.timeSettings.refreshTime) {
            let selectedTimeUnitNumber = activeDashboard.timeSettings.refreshTime
            clearInterval(this.intervalId);
            if (selectedTimeUnitNumber != "Off") {
              const mills = this.getMilliseconds(selectedTimeUnitNumber);
              this.startLogging(mills)
            } else {
              clearInterval(this.intervalId);
            }
          }
      }
    } 
  }

  loadMoreData() {
      this.setState((prevState) => {
      const totalTabs = Object.values(prevState.tabData).length;
      if (prevState.endIndex >= totalTabs) {
        // console.log("No more data to load");
        return null; 
      }
  
      let newStartIndex = 0;
      let newEndIndex = Math.min(prevState.endIndex + DEFAULT_ACCORDIANS_LOAD_COUNT, totalTabs); 
      
      return { startIndex: newStartIndex, endIndex: newEndIndex};
    });
  }
  
  startLogging = (mills) => {
    const { activeDashboard, activeDashlets } = this.props;
    console.log("handleLaodAomalyDashboardData ====>", mills)
    this.intervalId = setInterval(() => {
      if (this.props.activeDashboard && this.props.activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
        this.props.createAnomalyDashboard(this.props.activeDashboard)
      }
    }, mills); 
  }
  
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getMilliseconds(duration) {
    const unit = duration.slice(-1);
    const value = parseInt(duration.slice(0, -1));

    switch (unit) {
        case 's':
            return value * 1000;
        case 'M':
            return value * 60 * 1000;
        case 'h':
            return value * 60 * 60 * 1000;
        case 'd':
            return value * 24 * 60 * 60 * 1000;
        default:
            return 0;
    }
  }

setTabData = () => {
  const { activeDashboard } = this.props;

  if (activeDashboard && activeDashboard.accordionPanels) {
    let tabData = {};
    const panels = Object.values(activeDashboard.accordionPanels);
    for (let i = 0; i < panels.length; i++) {
      const panel = panels[i];
      tabData = {
        ...tabData,
        [panel.id]: {
          ...panel,
          editable: false,
          isOpen: this.state.tabData[panel.id] ? this.state.tabData[panel.id].isOpen : true,
        },
      };
    }
    this.setState({ tabData: tabData, allTabData: {...tabData}, isLoading: false });
    // this.setState({allTabData: {...tabData}})
  }
};

  onResizeStart = (layout, oldLayoutItem, layoutItem, placeholder) => {
    console.log("===> onResizeStart");
    // this.setState({isResizing: true});
  };

  onResizeStop = (layout, oldLayoutItem, layoutItem, placeholder) => {
    console.log("===> onResizeStop");
    // this.props.onChangeLayout(this.props.activeDashboard.id, layout);
    // this.setState({isResizing: false});
  };

  onDragStop = (layout, oldLayoutItem, layoutItem, placeholder) => {
    console.log("===> onDragStop");
    this.props.onChangeLayout(this.props.activeDashboard.id, layout);
  };

  toggleTab = (tabKey) => {
    const { tabData, isResizing } = this.state;
    const tab = tabData[tabKey];
    if (!tab.editable && !isResizing) {
      this.setState((prevState) => ({
        tabData: {
          ...prevState.tabData,
          [tabKey]: {
            ...tab,
            isOpen: !tab.isOpen,
          },
        },
      }));
    }
  };

  handleLabelClick = (event,index) => {
    event.stopPropagation();
    const tabsCopy = this.state.tabData;
    tabsCopy[index].editable = true;
    this.setState({ tabData: tabsCopy });
  };


  handleLabelBlur = (event, index) => {
    const tabsCopy = this.state.tabData;
    tabsCopy[index].name = event.target.value;
    tabsCopy[index].editable = true;
    this.setState({ tabData: tabsCopy });
  };

  handleKeyPress(event, tabId) {
    if (event.key === 'Enter') {
      this.handleBlur(tabId);
    }
  }

  handleBlur = (index) => {
    const tabsCopy = this.state.tabData;
    const updatedTab = tabsCopy[index];
    
    // Check if the label is not empty
    if (updatedTab.name.trim() !== "") {
      updatedTab.editable = false;
      this.setState({ tabData: tabsCopy });
  
      // Perform any additional actions, e.g., updating the accordion name
      this.props.updateAccordionName(updatedTab);
    } else {
      // Handle the case where the label is empty (display an error, prevent closing, etc.)
      // For now, just log a message to the console
      console.warn("Accordion label cannot be empty.");
    }
  };

  handleAddIconClick = (accordionId) => {
    const { onAddDashletHandler, activeDashboard } = this.props;
    if (onAddDashletHandler && activeDashboard) {
      onAddDashletHandler(activeDashboard.id, accordionId);
    }
  };

  handleDeleteIconClick = (accordionId) => {
    const { onDeleteAccordionHandler } = this.props;
    if (onDeleteAccordionHandler && this.state.tabData && Object.keys(this.state.tabData).length > 1) {
      onDeleteAccordionHandler(accordionId);
    } else {
      showErrorNotification("Delete accordion", "Not deleting Accordion as Single Accordion Present which is required.");
    }
  };

  onSearchNameChange = (searchName) => {
    this.setState({ searchName: searchName });
  
    if (searchName!="") {
      const tabDataArray = Object.values(this.state.allTabData);

      const filteredData = tabDataArray.filter((item) => {
        const matchesName = searchName
          ? item.name.toLowerCase().includes(searchName.toLowerCase())
          : true;
    
        return matchesName;
      });
      this.setState({ tabData: filteredData });
    } else {
      this.setTabData();
    }

  };

  onFilterDatabaseChange = (databaseName) => {
    this.setState({ databaseName: databaseName });
    if (databaseName != "") {
      this.setTabData();
      const tabDataArray = Object.values(this.state.allTabData);

      const filteredData = tabDataArray.filter((item) => {
        const matchesName = databaseName
          ? item.databaseType.toLowerCase().includes(databaseName.toLowerCase())
          : true;
    
        return matchesName;
      });
      this.setState({ tabData: filteredData });
    } else {
      this.setTabData();
    }
  }

  renderDashboard = () => {
    // const {classes, isLeftMenuOpened, activeDashboard, activeDashlets, activeDashboardLayout, removeDashlets} = this.props;
    const {classes, isLeftMenuOpened, activeDashboard, activeDashlets, removeDashlets} = this.props;
    const{ tabData, startIndex, endIndex } = this.state;

    const activeDashboardLayout = [
      {
        "x": 0,
        "y": 0,
        "i": "101",
        "w": 12,
        "h": 10,
        "moved": false,
        "static": false
      },
      {
        "x": 0,
        "y": 0,
        "i": "111",
        "w": 12,
        "h": 10,
        "moved": false,
        "static": false
      }
    ]

    if (!activeDashboard) {
      return null;
    } else {
      const dashletsToRemove = [];
      activeDashboard.dashlets.filter(dashletId => {
        const dashlet = activeDashlets[dashletId];
        if (dashlet.config.autoDelete && dashlet.config.dashletDeleteTime < new Date().getTime()) {
            dashletsToRemove.push(dashlet);
            return false; // Remove this dashlet from the list
        }
        return true; // Keep this dashlet in the list
      });
      if (dashletsToRemove.length > 0) {
        removeDashlets(dashletsToRemove);
      }
    }

  return [
    <DashboardToolbarContainer
      searchName={this.state.searchName}
      filterDatabase={this.state.filterDatabase}
      onFilterDatabaseChange={this.onFilterDatabaseChange}
      onSearchNameChange={this.onSearchNameChange}
      key="dash-toolbar-container-1586127555834"
    />,

    <div
      key="dash-scroll-wrapper-container-1586127555834"
      className={classes.scrollbarWrapper}
    >
      {activeDashboard.dashboardType === ANOMALY_DASHBOARD ? (
        <>
          <Divider />
          <HLCUserSessionWebSocketSubscription
            key={`anomly-dashboard-response-subscription`}
            subscriptionDestination={LOAD_ANOMALY_DASHBOARD_DATA_SUBSCRIPTION}
            handleMessage={(response) =>
              this.props.handleLaodAomalyDashboardData(response)
            }
          />
        </>
      ) : null}
      {/* <Scrollbars> */}
    
      <Scrollbars
        onUpdate={(values) => {
          if (activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
            const { scrollTop, scrollHeight, clientHeight } = values;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
              this.loadMoreData();
            }
          }
        }}
      >
        <PEIChartBagWebSocketSubscription />

        {!this.state.isLoading && <div>
          {/*<ReactGridLayout*/}
          {/*  key={`dash-grid-${isLeftMenuOpened}`}*/}
          {/*  cols={12}*/}
          {/*  rowHeight={30}*/}
          {/*  layout={activeDashboardLayout}*/}
          {/*  isDraggable={false}*/}
          {/*  isResizable={false}*/}
          {/*  // onResizeStart={this.onResizeStart}*/}
          {/*      // onResizeStop={this.onResizeStop}*/}
          {/*      // onDragStart={() => console.log("===> onDragStart")}*/}
          {/*      // onDragStop={this.onDragStop}*/}
          {/*      // draggableHandle=".GridDragHandler"*/}
          {/*>*/}
          {activeDashboard.dashboardType !== ANOMALY_DASHBOARD
            ? Object.values(tabData).map((tab, index) => {
                const tabId = tab.id;
                const { name, editable, isOpen } = tab;
                const repeat = activeDashboard.layoutRepeat;
                let gridTemplateColumnsValue =
                  repeat !== 0
                    ? `repeat(${repeat}, ${100 / repeat - 1}%)`
                    : "repeat(2, 50%)";
                if (activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
                  gridTemplateColumnsValue = `repeat(5, ${100 / 5 - 1}%)`;
                }
                return (
                  <div key={tabId} className="panel">
                    <div
                      key={tabId}
                      className={classes.menuItem}
                      style={{ paddingBottom: this.state[tabId] ? "0" : "0px" }}
                    >
                      <div
                        className={classes.dashboardRow}
                        onClick={() => this.toggleTab(tabId)}
                      >
                        {isOpen ? (
                          <ChevronDown12Regular
                            style={{ height: "18px", width: "18px" }}
                          />
                        ) : (
                          <ChevronRight12Regular
                            style={{ height: "18px", width: "18px" }}
                          />
                        )}
                        {activeDashboard.dashboardType !== ANOMALY_DASHBOARD ? (
                          <>
                            {editable ? (
                              <input
                                style={{ zIndex: 999 }}
                                type="text"
                                autoFocus={true}
                                value={name}
                                onKeyUpCapture={(e) =>
                                  this.handleKeyPress(e, tabId)
                                }
                                onChange={(e) => this.handleLabelBlur(e, tabId)}
                                onBlur={() => this.handleBlur(tabId)}
                                className={classes.editbarWrapper}
                              />
                            ) : (
                              <span
                                style={{ zIndex: 999 }}
                                onClick={(e) => this.handleLabelClick(e, tabId)}
                              >
                                {name}
                              </span>
                            )}
                          </>
                        ) : (
                          <span
                            style={{ zIndex: 999 }}
                            onClick={() => this.toggleTab(tabId)}
                          >
                            {name}
                          </span>
                        )}
                      </div>
                      {activeDashboard.dashboardType !== ANOMALY_DASHBOARD ? (
                        <div>
                          <Tooltip title="Add Dashlet">
                            <Add20Filled
                              className={classes.smallAddIcon}
                              onClick={() => this.handleAddIconClick(tabId)}
                            />
                          </Tooltip>
                          <Tooltip title="Remove Panel">
                            <Delete20Filled
                              className={classes.smallDeleteIcon}
                              onClick={() => this.handleDeleteIconClick(tabId)}
                            />
                          </Tooltip>
                        </div>
                      ) : null}
                    </div>
                    <div
                      key={uuid.v4()}
                      style={{
                        display: "grid",
                        justifyContent: "space-between",
                        gridTemplateColumns: gridTemplateColumnsValue,
                        padding: isOpen
                          ? activeDashboard.dashboardType === ANOMALY_DASHBOARD
                            ? "12px"
                            : "0"
                          : "0px",
                        width: isOpen
                          ? activeDashboard.dashboardType === ANOMALY_DASHBOARD
                            ? "calc(100% - 48px)"
                            : "calc(100% - 24px)"
                          : "calc(100% - 24px)",
                        border: "1px solid #e1e1e1",
                        borderTop: "none",
                        gridGap: "10px",
                      }}
                    >
                      {isOpen &&
                        (tab.dashlets &&
                        Object.keys(tab.dashlets).length > 0 ? (
                          _.map(tab.dashlets, (dashletId, index) => {
                            const dashlet = activeDashlets[dashletId];
                            const dashletHeight =
                              activeDashboard.dashboardType ===
                              ANOMALY_DASHBOARD
                                ? "100%"
                                : activeDashboard.dashboardType ===
                                  DEFAULT_DASHBOARD
                                ? "250px"
                                : "390px";
                            const dashletPadding =
                              activeDashboard.dashboardType ===
                              ANOMALY_DASHBOARD
                                ? "0"
                                : activeDashboard.dashboardType ===
                                  DEFAULT_DASHBOARD
                                ? "6px"
                                : "12px";
                            return (
                              <div
                                key={uuid.v4()}
                                style={{
                                  height: dashletHeight,
                                  padding: dashletPadding,
                                }}
                              >
                                <DashletContainer
                                  key={`dsh-cont-1586127555834-${dashletId}`}
                                  dashboardType={activeDashboard.dashboardType}
                                  dashlet={dashlet}
                                  isResizing={this.state.isResizing}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NewDashlet
                            dashboardData={activeDashboard}
                            onAddDashletHandler={() =>
                              this.handleAddIconClick(tabId)
                            }
                          />
                        ))}
                    </div>
                  </div>
                );
              })
            : Object.values(tabData)
                .slice(startIndex, endIndex)
                .map((tab, index) => {

                  const tabId = tab.id;
                  const { name, editable, isOpen, dashlets } = tab;
                  const repeat = activeDashboard.layoutRepeat;
                  let gridTemplateColumnsValue =
                    repeat !== 0
                      ? `repeat(${repeat}, ${100 / repeat - 1}%)`
                      : "repeat(2, 50%)";
                  if (activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
                    gridTemplateColumnsValue = `repeat(5, ${100 / 5 - 1}%)`;
                  }
                  return (
                    <div key={tabId} className="panel">
                      <div
                        key={tabId}
                        className={classes.menuItem}
                        style={{
                          paddingBottom: this.state[tabId] ? "0" : "0px",
                        }}
                      >
                        <div
                          className={classes.dashboardRow}
                          onClick={() => this.toggleTab(tabId)}
                        >
                          {isOpen ? (
                            <ChevronDown12Regular
                              style={{ height: "18px", width: "18px" }}
                            />
                          ) : (
                            <ChevronRight12Regular
                              style={{ height: "18px", width: "18px" }}
                            />
                          )}
                          {activeDashboard.dashboardType !==
                          ANOMALY_DASHBOARD ? (
                            <>
                              {editable ? (
                                <input
                                  style={{ zIndex: 999 }}
                                  type={"text"}
                                  autoFocus={true}
                                  value={name}
                                  onKeyUpCapture={(e) =>
                                    this.handleKeyPress(e, tabId)
                                  }
                                  onChange={(e) =>
                                    this.handleLabelBlur(e, tabId)
                                  }
                                  onBlur={() => this.handleBlur(tabId)}
                                  className={classes.editbarWrapper}
                                />
                              ) : (
                                <span
                                  style={{ zIndex: 999 }}
                                  onClick={(e) =>
                                    this.handleLabelClick(e, tabId)
                                  }
                                >
                                  {name}
                                </span>
                              )}
                            </>
                          ) : (
                            <span
                              style={{ zIndex: 999 }}
                              onClick={() => this.toggleTab(tabId)}
                            >
                              {name}
                            </span>
                          )}
                        </div>
                        {activeDashboard.dashboardType != ANOMALY_DASHBOARD ? (
                          <div>
                            <Tooltip title="Add Dashlet">
                              <Add20Filled
                                className={classes.smallAddIcon}
                                onClick={() => this.handleAddIconClick(tabId)}
                              />
                            </Tooltip>
                            <Tooltip title="Remove Panel">
                              <Delete20Filled
                                className={classes.smallDeleteIcon}
                                onClick={() =>
                                  this.handleDeleteIconClick(tabId)
                                }
                              />
                            </Tooltip>
                          </div>
                        ) : null}
                      </div>
                      <div
                        key={uuid.v4()}
                        style={{
                          display: "grid",
                          justifyContent: "space-between",
                          gridTemplateColumns: gridTemplateColumnsValue,
                          padding: isOpen
                            ? activeDashboard.dashboardType ===
                              ANOMALY_DASHBOARD
                              ? "12px"
                              : "0"
                            : "0px",
                          width: isOpen
                            ? activeDashboard.dashboardType ===
                              ANOMALY_DASHBOARD
                              ? "calc(100% - 48px)"
                              : "calc(100% - 24px)"
                            : "calc(100% - 24px)",
                          border: "1px solid #e1e1e1",
                          borderTop: "none",
                          gridGap: "10px",
                        }}
                      >
                       {isOpen && (tab.dashlets &&
                        Object.keys(tab.dashlets).length > 0 ? (
                          _.map(tab.dashlets, (dashletId, index) => {
                            const dashlet = activeDashlets[dashletId];
                            const dashletHeight = activeDashboard.dashboardType === ANOMALY_DASHBOARD ? "100%" 
                                : activeDashboard.dashboardType === DEFAULT_DASHBOARD ? "250px" : "390px";
                            const dashletPadding = activeDashboard.dashboardType === ANOMALY_DASHBOARD ? "0"
                                : activeDashboard.dashboardType === DEFAULT_DASHBOARD ? "6px" : "12px";
                            return (
                              <div key={uuid.v4()} style={{ height: dashletHeight, padding: dashletPadding }}>
                                <DashletContainer
                                  key={`dsh-cont-1586127555834-${dashletId}`}
                                  dashboardType={activeDashboard.dashboardType}
                                  dashlet={dashlet}
                                  isResizing={this.state.isResizing}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <NewDashlet
                            dashboardData={activeDashboard}
                            onAddDashletHandler={() =>
                              this.handleAddIconClick(tabId)
                            }
                          />
                        ))}
                      </div>
                    </div>
                  );
                })}
          {/*</ReactGridLayout>*/}
        </div>}
        {/* {this.state.isLoading && 
            <div style={{ textAlign: "center", padding: "20px" }}>
              <CircularProgress />
            </div>} */}
        {Object.values(tabData).length == 0 &&
          activeDashboard.dashboardType === ANOMALY_DASHBOARD && (
            <div className={classes.content}>
              Please verify that the selected backend has targets.
              Alternatively, adjust the time range settings to fetch more
              specific data to display in the Anomaly Dashboard.
            </div>
          )}
        {activeDashboard.dashboardType === ANOMALY_DASHBOARD &&
          endIndex < Object.values(tabData).length && (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <CircularProgress />
            </div>
          )}
      </Scrollbars>
    </div>,
    <PaletteContainer key="dash-grp-palette-1586127650606" />,
  ];
};

  render() {
    const {classes} = this.props;

    return (
        <main className={classes.root}>
          {this.renderDashboard()}
        </main>
    );
  }
}

DashboardWorkspace.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  isLeftMenuOpened: PropTypes.bool,
  activeDashboard: PropTypes.object,
  activeDashboardLayout: PropTypes.array,
  //activeDashboardLayout: PropTypes.object,
  activeDashlets: PropTypes.object,
  refreshDashboardInit: PropTypes.object,

  //func
  onChangeLayout: PropTypes.func,
  removeDashlets: PropTypes.func,
  onAddDashletHandler: PropTypes.func,
  updateAccordionName: PropTypes.func,
  onDeleteAccordionHandler: PropTypes.func,
  createAnomalyDashboard: PropTypes.func,
  handleLaodAomalyDashboardData: PropTypes.func,
  setRefreshDashboardInit: PropTypes.func,
  //...
};

export default withStyles(styles)(DashboardWorkspace);
